import { useAuth0 } from '@auth0/auth0-react'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import {
  Alert,
  Container,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { Store } from '../../Store'
import { RectButton, RoundedButton } from '../../components/button'
import { InputTitle, RequiredInputTag } from '../../components/form'
import { CheckoutStepper } from '../../components/stepper'
import { PageTitle, SectionTitle } from '../../components/typography'
import { useSnackbar } from '../../context/snackbarContext'
import { useShippingInfo } from '../../hooks/shippingInfoHooks'
import { EditShippingInfoDialog } from '../../components/EditShippingInfoDialog'
import { useUserInfo } from '../../hooks/userInfoHooks'
import { useBcpConnectedOrgPlaces } from '../../hooks/bcpHooks'
import { useCreateCheckoutSession } from '../../hooks/stripeHooks'
import { getError } from '../../utils'
import { ApiError } from '../../types/ApiError'

export const OrderConfirmationLoadingContext = createContext<{
  loading: boolean
  setLoading: (loading: boolean) => void
}>({
  loading: false,
  setLoading: () => {
    throw new Error('setLoading not implemented')
  }
})

export default function OrderConfirmationPage() {
  const { loginWithRedirect, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const { state, dispatch } = useContext(Store)
  const { userInfo } = state
  const snackbar = useSnackbar()

  const [dropDown, setDropdown] = useState('0')
  const [fullName, setFullName] = useState('')
  const [fullNameKana, setFullNameKana] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [bcpConnectedOrgPlaceId, setBcpConnectedOrgPlaceId] = useState('')

  const { data: shippingInfo } = useShippingInfo(getAccessTokenSilently, getAccessTokenWithPopup)

  const [nameError, setNameError] = useState('')
  const [nameKanaError, setNameKanaError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [postalCodeError, setPostalCodeError] = useState('')
  const [addressError, setAddressError] = useState('')
  const [showAddAddress, setShowAddAddress] = useState(true)
  const [loading, setLoading] = useState(false)
  const [formIsValid, setFormIsValid] = useState(true)

  const orderConfirmationFormRef = useRef<HTMLFormElement>(null)
  const checkoutFormRef = useRef<HTMLFormElement>(null)

  const [openEditShippingInfoDialog, setOpenEditSShippingInfoDialog] = useState(false)

  const hasError = useMemo(
    () => nameError || nameKanaError || phoneError || postalCodeError || addressError,
    [nameError, nameKanaError, phoneError, postalCodeError, addressError]
  )
  const shippingAddress = useMemo<{
    fullName: string
    fullNameKana: string
    postalCode: string
    address: string
    phone: string
  }>(
    () => ({
      fullName,
      fullNameKana,
      postalCode,
      address,
      phone
    }),
    [address, fullName, fullNameKana, phone, postalCode]
  )

  const createCheckoutSession = useCreateCheckoutSession()

  const handleCheckoutSession = async () => {
    try {
      const checkoutSessionResponse = await createCheckoutSession.mutateAsync({
        shippingAddress: shippingAddress,
        bcpConnectedOrgPlaceId: bcpConnectedOrgPlaceId
      })

      window.location.href = checkoutSessionResponse.returnUrl!
    } catch (err) {
      alert(getError(err as ApiError))
    }
  }

  useEffect(() => {
    if (!userInfo) {
      loginWithRedirect({
        appState: {
          returnTo: '/'
        },
        authorizationParams: {
          screen_hint: 'signup'
        }
      })
    }

    if (shippingInfo?.shippingInfo) {
      setShowAddAddress(false)
      setFullName(shippingInfo.shippingInfo.shippingAddress[0].fullName)
      setPostalCode(shippingInfo.shippingInfo.shippingAddress[0].postalCode)
      setAddress(shippingInfo.shippingInfo.shippingAddress[0].address)
      setPhone(shippingInfo.shippingInfo.shippingAddress[0].phone)
    }
  }, [shippingInfo, loginWithRedirect, userInfo])

  //お届け先を選択で選択したら、localstorageに設定
  const handleDropDownAddress = (dropdownValue: string) => {
    if (!shippingInfo?.shippingInfo) return
    setDropdown(dropdownValue)
    shippingInfo.shippingInfo.shippingAddress.map((addressData, key) => {
      if (key.toString() === dropdownValue) {
        setPostalCode(addressData.postalCode)
        setAddress(addressData.address)

        const fullName = addressData.fullName
        const postalCode = addressData.postalCode
        const address = addressData.address
        const phone = addressData.phone

        dispatch({
          type: 'SAVE_SHIPPING_ADDRESS',
          payload: {
            fullName,
            postalCode,
            address,
            phone
          }
        })
      }
    })
  }

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setLoading(true)
    if (!formIsValid) {
      snackbar.showSnackbar('不正な入力があります', 'warning')
      setLoading(false)
      return
    }

    if (!hasError) {
      dispatch({
        type: 'SAVE_SHIPPING_ADDRESS',
        payload: {
          fullName,
          postalCode,
          address,
          phone
        }
      })

      if (!checkoutFormRef.current) {
        snackbar.showSnackbar('支払いフォームが準備中です', 'warning')
        setLoading(false)
        return
      }
      checkoutFormRef.current.requestSubmit()
    }
  }

  const handleNameValidation = (fullNameInput: string) => {
    let formIsValid = true
    setFullName(fullNameInput)
    //Name
    if (!fullNameInput) {
      formIsValid = false
      setNameError('*氏名は未入力です')
    } else if (fullNameInput.length > 30) {
      formIsValid = false
      setNameError('*氏名は30桁以上は入力できません')
    }

    //name 全角確認
    for (let i = 0; i < fullNameInput.length; i++) {
      const character = fullNameInput.charAt(i)
      if (!character.match(/^[a-zA-Z\s0-9ぁ-んァ-ヶー一-龥々]+$/)) {
        formIsValid = false
        setNameError('*記号文字は入力できません')
      }
    }

    if (formIsValid) {
      setNameError('')
    }

    setFormIsValid(formIsValid)
    return formIsValid
  }

  const handleNameKanaValidation = (kanaInput: string) => {
    let formIsValid = true
    setFullNameKana(kanaInput)
    //フリガナ
    if (!kanaInput) {
      formIsValid = false
      setNameKanaError('*フリガナは未入力です')
      return formIsValid
    } else if (kanaInput.length > 30) {
      formIsValid = false
      setNameKanaError('*フリガナは30桁以上は入力できません')
      return formIsValid
    }

    //フリガナ 半角確認
    for (let i = 0; i < kanaInput.length; i++) {
      const character = kanaInput.charAt(i)
      if (!character.match(/^[\sァ-ンー]+$/)) {
        formIsValid = false
        setNameKanaError('*全角文字カタカナのみ可能です')
        return formIsValid
      }
    }

    if (formIsValid) {
      setNameKanaError('')
    }

    setFormIsValid(formIsValid)
    return formIsValid
  }

  const handlePhoneValidation = (phoneInput: string) => {
    let formIsValid = true
    setPhone(phoneInput)
    //電話番号
    if (!phoneInput) {
      formIsValid = false
      setPhoneError('*電話番号は未入力です')
      return formIsValid
    } else if (phoneInput.length > 11) {
      formIsValid = false
      setPhoneError('*電話番号は11桁以上は入力できません')
      return formIsValid
    }

    //電話番号 半角確認
    for (let i = 0; i < phoneInput.length; i++) {
      const character = phoneInput.charAt(i)
      if (!character.match(/^[a-zA-Z0-9]+$/)) {
        formIsValid = false
        setPhoneError('*全角文字は入力できません')
        return formIsValid
      }
    }

    //電話番号 数値確認
    const isnum = /^\d+$/.test(phoneInput)
    if (!isnum) {
      formIsValid = false
      setPhoneError('*数値のみ入力可能です')
      return formIsValid
    }

    const regex =
      /^(0[5-9]0[-(]?[0-9]{4}[-)]?[0-9]{4}|0120[-]?\d{1,3}[-]?\d{4}|050[-]?\d{4}[-]?\d{4}|0[1-9][-]?\d{1,4}[-]?\d{1,4}[-]?\d{4})*$/
    if (!regex.test(phoneInput)) {
      formIsValid = false
      setPhoneError('*有効な電話番号を入力してください')
      return formIsValid
    }

    if (formIsValid) {
      setPhoneError('')
    }
    setFormIsValid(formIsValid)
    return formIsValid
  }

  const handlePostalCodeValidation = (postalCodeInput: string) => {
    let formIsValid = true
    setPostalCode(postalCodeInput)
    //郵便番号
    if (!postalCodeInput) {
      formIsValid = false
      setPostalCodeError('*郵便番号は未入力です')
      return formIsValid
    } else if (postalCodeInput.length > 7) {
      formIsValid = false
      setPostalCodeError('*郵便番号は7桁以上は入力できません')
      return formIsValid
    }

    //郵便番号 半角確認
    for (let i = 0; i < postalCodeInput.length; i++) {
      const character = postalCodeInput.charAt(i)
      if (!character.match(/^[a-zA-Z0-9]+$/)) {
        formIsValid = false
        setPostalCodeError('*全角文字は入力できません')
        return formIsValid
      }
    }

    //郵便番号 数値確認
    const isnum = /^\d+$/.test(postalCodeInput)
    if (!isnum) {
      formIsValid = false
      setPostalCodeError('*数値のみ入力可能です')
      return formIsValid
    }

    const regex = /^[0-9]{7}$/
    if (!regex.test(postalCodeInput)) {
      formIsValid = false
      setPostalCodeError('*ハイフン無しの有効な郵便番号を入力してください')
      return formIsValid
    }

    if (formIsValid) {
      setPostalCodeError('')
    }

    setFormIsValid(formIsValid)
    return formIsValid
  }

  const handleAddressValidation = (addressInput: string) => {
    let formIsValid = true
    setAddress(addressInput)
    //住所
    if (!addressInput) {
      formIsValid = false
      setAddressError('*住所は未入力です')
      return formIsValid
    } else if (addressInput.length > 50) {
      formIsValid = false
      setAddressError('*住所は50桁以上は入力できません')
      return formIsValid
    }

    if (formIsValid) {
      setAddressError('')
    }

    setFormIsValid(formIsValid)
    return formIsValid
  }

  const handleEditShippingInfo = useCallback(() => {
    setOpenEditSShippingInfoDialog(true)
  }, [])

  const handleCloseEditShippingInfoDialog = useCallback(() => {
    setOpenEditSShippingInfoDialog(false)
  }, [])

  return (
    <OrderConfirmationLoadingContext.Provider value={{ loading, setLoading }}>
      <Helmet>
        <title>Visnu EC - ご注文内容確認</title>
      </Helmet>
      <Container maxWidth="md" sx={{ position: 'relative', pt: '200px' }}>
        <Stack gap={6}>
          <PageTitle title="Order Confirmation" subtitle="ご注文内容確認" />
          <CheckoutStepper currentStep={1} />
        </Stack>
      </Container>

      <Container maxWidth="md" sx={{ position: 'relative', pt: 6, pb: '130px' }}>
        <Stack
          ref={orderConfirmationFormRef}
          component={'form'}
          onSubmit={(e) => {
            submitHandler(e)
          }}
          id="order-confirmation-form"
          gap={6}
          sx={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>商品のお届け先</SectionTitle>
            <Stack px="30px" gap="5px">
              {shippingInfo?.shippingInfo ? (
                <>
                  <Stack direction={'row'} alignItems={'center'} gap="50px">
                    <InputTitle>お届け先の住所を選択</InputTitle>
                    <RequiredInputTag />
                    <FormControl fullWidth>
                      <Select
                        value={dropDown.toString()}
                        onChange={(e) => {
                          handleDropDownAddress(e.target.value)
                        }}
                        required={address === 'false'}
                      >
                        {shippingInfo.shippingInfo.shippingAddress!.map((addressInfo, key) => (
                          <MenuItem value={key.toString()}>{addressInfo.address}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                  <br />
                  <Stack direction={'row'} alignItems={'center'} gap="50px">
                    <InputTitle>お届け先の住所</InputTitle>
                    <Stack
                      sx={{
                        width: 70,
                        minWidth: 70,
                        height: 20,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    ></Stack>
                    <Typography fontSize={16} fontWeight={600}>
                      〒{postalCode}
                      <br />
                      {address}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} gap="50px">
                    <InputTitle></InputTitle>
                    <Stack
                      sx={{
                        width: 70,
                        minWidth: 70,
                        height: 20,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    ></Stack>
                    <Stack sx={{ width: '100%' }}>
                      <Divider sx={{ bgcolor: '#B1B1B1' }} />
                    </Stack>
                  </Stack>
                  <RectButton
                    variant="contained"
                    sx={{
                      width: 200,
                      background: '#236CB5',
                      float: 'right',
                      marginLeft: 'auto',
                      marginRight: 0
                    }}
                    startIcon={<ModeEditIcon />}
                    onClick={handleEditShippingInfo}
                  >
                    お届け先住所を編集
                  </RectButton>
                  <br />
                </>
              ) : (
                <div />
              )}
              <Stack direction={'row'} alignItems={'center'} gap="50px">
                <InputTitle>氏名</InputTitle>
                <RequiredInputTag />
                <StyledTextField
                  size="small"
                  value={fullName}
                  sx={{
                    '&  .MuiFormHelperText-contained': {
                      margin: '0',
                      backgroundColor: '#FFFCF8'
                    }
                  }}
                  onChange={(e) => handleNameValidation(e.target.value)}
                  inputProps={{ maxLength: 30 }}
                  required
                  error={nameError !== ''}
                  helperText={nameError ? nameError : ' '}
                />
              </Stack>

              <Stack direction={'row'} alignItems={'center'} gap="50px">
                <InputTitle>フリガナ</InputTitle>
                <RequiredInputTag />
                <StyledTextField
                  size="small"
                  value={fullNameKana}
                  sx={{
                    '&  .MuiFormHelperText-contained': {
                      margin: '0',
                      backgroundColor: '#FFFCF8'
                    }
                  }}
                  onChange={(e) => handleNameKanaValidation(e.target.value)}
                  inputProps={{ maxLength: 30 }}
                  required
                  error={nameKanaError !== ''}
                  helperText={nameKanaError ? nameKanaError : ' '}
                />
              </Stack>
              <Stack direction={'row'} alignItems={'center'} gap="50px">
                <InputTitle>電話番号</InputTitle>
                <RequiredInputTag />
                <StyledTextField
                  size="small"
                  value={phone}
                  sx={{
                    '&  .MuiFormHelperText-contained': {
                      margin: '0',
                      backgroundColor: '#FFFCF8'
                    }
                  }}
                  onChange={(e) => handlePhoneValidation(e.target.value)}
                  inputProps={{ maxLength: 11 }}
                  required
                  error={phoneError !== ''}
                  helperText={phoneError ? phoneError : ' '}
                />
              </Stack>
            </Stack>
            <Stack px="30px" gap="14px">
              <>
                <Stack direction={'row'} alignItems={'center'} gap="50px" display={showAddAddress ? 'flex' : 'none'}>
                  <InputTitle>郵便番号</InputTitle>
                  <RequiredInputTag />
                  <StyledTextField
                    size="small"
                    sx={{
                      width: 190,
                      '&  .MuiFormHelperText-contained': {
                        margin: '0',
                        backgroundColor: '#FFFCF8',
                        marginRight: '-150px'
                      }
                    }}
                    value={postalCode}
                    onChange={(e) => handlePostalCodeValidation(e.target.value)}
                    inputProps={{ maxLength: 7 }}
                    required
                    error={postalCodeError !== ''}
                    helperText={postalCodeError ? postalCodeError : ' '}
                  />
                </Stack>
                <Stack direction={'row'} alignItems={'center'} gap="50px" display={showAddAddress ? 'flex' : 'none'}>
                  <InputTitle>住所</InputTitle>
                  <RequiredInputTag />
                  <StyledTextField
                    size="small"
                    sx={{
                      width: '100%',
                      '&  .MuiFormHelperText-contained': {
                        margin: '0',
                        backgroundColor: '#FFFCF8'
                      }
                    }}
                    value={address}
                    onChange={(e) => handleAddressValidation(e.target.value)}
                    inputProps={{ maxLength: 50 }}
                    required
                    error={addressError !== ''}
                    helperText={addressError ? addressError : ' '}
                  />
                </Stack>
              </>
            </Stack>
          </Stack>
          <VisnuBcpSection selectedPlaceId={bcpConnectedOrgPlaceId} onChangePlaceId={setBcpConnectedOrgPlaceId} />

          <EditShippingInfoDialog
            open={openEditShippingInfoDialog}
            onClose={() => {
              handleCloseEditShippingInfoDialog()
            }}
          />
        </Stack>
        <Stack gap={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ width: '100%' }} gap={3}></Stack>
          <Stack direction={'row'} gap={5} justifyContent={'center'}>
            <ReturnToCartButton />
            <ConfirmOrderButton
              onClick={() => {
                handleCheckoutSession()
              }}
              loading={loading}
            />
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
            <Typography fontSize={16} fontWeight={600}>
              ＊口座振り込みを希望のお客様は<Link to={'/contact'}>お問合せフォーム</Link>よりお問い合わせください。
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </OrderConfirmationLoadingContext.Provider>
  )
}

const StyledTextField = styled(TextField)(() => ({
  width: 350,
  background: '#FFFFFF'
}))

const ReturnToCartButton = () => {
  return (
    <RoundedButton
      component={Link}
      to="/cart"
      color="gray"
      startIcon={
        <ExpandCircleDownIcon
          sx={{
            transform: 'rotate(90deg)',
            color: '#B1B1B1',
            fontSize: '16px !important'
          }}
        />
      }
    >
      カートへ戻る
    </RoundedButton>
  )
}

const ConfirmOrderButton = ({ onClick, loading }: { onClick: () => void; loading?: boolean }) => {
  return (
    <RoundedButton
      type="submit"
      onClick={onClick}
      color="orange"
      endIcon={
        <ExpandCircleDownIcon
          sx={{
            transform: 'rotate(270deg)',
            fontSize: '16px !important'
          }}
        />
      }
      loading={loading}
    >
      注文確定へ
    </RoundedButton>
  )
}

const VisnuBcpSection = ({
  selectedPlaceId,
  onChangePlaceId
}: {
  selectedPlaceId: string
  onChangePlaceId: (placeId: string) => void
}) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  // hooks
  const userInfo = useUserInfo(getAccessTokenSilently, getAccessTokenWithPopup)
  const bcpPlaces = useBcpConnectedOrgPlaces()

  // memo
  const isAccountConnected = useMemo<boolean>(
    () => Boolean(userInfo.data?.bcpConnectedOrg),
    [userInfo.data?.bcpConnectedOrg]
  )
  const isAccountConnectionError = useMemo<boolean>(
    () => Boolean(userInfo.data?.bcpConnectedOrg?.error),
    [userInfo.data?.bcpConnectedOrg?.error]
  )

  return (
    <Stack sx={{ width: '100%' }} gap={3}>
      <SectionTitle>VisnuBCP連携</SectionTitle>
      <Stack px="30px" gap="5px">
        <Stack direction={'row'} alignItems={'center'} gap="50px" height={60}>
          <InputTitle sx={{ minWidth: 250 }}>連携組織</InputTitle>
          <Typography pl={1}>
            {isAccountConnectionError ? '連携エラー' : userInfo.data?.bcpConnectedOrg?.name ?? '未連携'}
          </Typography>
        </Stack>
        {!isAccountConnectionError ? (
          isAccountConnected ? (
            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle sx={{ minWidth: 250 }}>自動登録先の保管場所</InputTitle>
              <FormControl fullWidth>
                <Select
                  value={selectedPlaceId}
                  onChange={(e) => {
                    onChangePlaceId(e.target.value)
                  }}
                  displayEmpty
                  size="small"
                  sx={{
                    background: '#FFFFFF'
                  }}
                >
                  <MenuItem value={''}>{'登録しない'}</MenuItem>
                  {bcpPlaces.data?.places.map((place) => (
                    <MenuItem key={place._id} value={place._id}>
                      {place.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          ) : (
            <Alert severity="info">
              <Typography>
                VisnuBCPとアカウント連携をすることで、商品購入時にVisnuBCPへの在庫の自動登録が可能となります。
              </Typography>
              <Typography>
                <Link to={'/mypage'}>My Page</Link>
                から連携設定を行ってください。
              </Typography>
            </Alert>
          )
        ) : (
          <Alert severity="error">
            <Typography>アカウント連携のエラーが発生しているため、自動登録が出来ません。</Typography>
            <Typography>
              <Link to={'/mypage'}>My Page</Link>
              からご確認ください。
            </Typography>
          </Alert>
        )}
      </Stack>
    </Stack>
  )
}
