import { useMutation, useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

type GetAccessTokenSilentlyType = Parameters<typeof getAPIAccessToken>[0]
type GetAccessTokenWithPopupType = Parameters<typeof getAPIAccessToken>[1]

/**
 * ユーザの配送先住所を取得するAPI
 * 例: get /api/shipping/info
 */
export const useShippingInfo = (
  getAccessTokenSilently: GetAccessTokenSilentlyType,
  getAccessTokenWithPopup: GetAccessTokenWithPopupType
) => {
  return useQuery({
    queryKey: ['shippingInfo'],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/shipping/info'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 * ユーザの配送先住所を更新するAPI
 * 例: put /api/shipping/info
 */
type UpdateShippingClient = (typeof apiClients)['/api/shipping/info']['PUT']['client']

export const useUpdateShippingAddress = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (body: Parameters<UpdateShippingClient>[0]['body']) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/shipping/info'].PUT.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        body
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}
